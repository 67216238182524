// If you want to override variables do it here
@import "variables";

// Import styles
@import "~@coreui/coreui/scss/coreui.scss";

// Temp fix for reactstrap
@import '~@coreui/coreui/scss/_dropdown-menu-right.scss';

// If you want to add something do it here
@import "custom";

// ie fixes
@import "ie-fix";
header.app-header{
  // height: 34px;
  height: calc(18%);
  @media (max-width: 980px){
    height: calc(11%);
  }
  border: 0px;
  z-index: 999;
  box-shadow: none;
}
@media (min-width: 992px){
  .header-fixed .app-body{
    margin-top: 0;
  }
}
@media (max-width: 991.98px){
  .app .app-body{
    margin-top: 0 !important;
  }
}
@media (max-width: 991.98px){
  header.app-header.navbar {
      padding-top: 0 !important;
  }
}
.font-large.col-12.white-bottom-border.btn.transparent {
  margin-left: auto;
  margin-right: auto;
  display: block;
}
.pheader-10 {
  padding-top: 26px;
}
section.subheader {
  position: fixed;
  top: 30px;
  height: 60px;
  min-height: 60px;
  left: 0px;
  right: 0px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center -20px;
  z-index: 100;
}
.footer-bg{
  a.icon {
    font-size: 24px;
    padding-right: 10px;
    color: #FFFFFF;
  }
  a.icon:hover{
    color: #20a8d8;
  }
}
footer.app-footer {
    position: fixed;
    bottom: 0px;
    height: 60px;
    z-index: 1000;
    left: 0px;
    right: 0px;
    background-color: transparent;
    border: 0px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
    .content-div{
      height: 20px;
      margin-top: 10px;
    }
    a{
      color: #ffffff;
    }
    section.container-fluid.margin-top-20{
      height: 46px;
      .content-div{
        padding-bottom: 11px;
        height: 40px;
      }
    }
}
.margin-top-20{
  margin-top: 10px;
}
.app-header{
}
.app-header{
  .react-search-field,.react-search-field-input,.react-search-field-button{
    border: 0px !important;
  }
}
.col.no-side-padding{
  input{
    border: 0px;
  }
  .input-group-text{
    padding-right: 0px;
    padding-left: 0px;
    background: transparent;
    border: 0px;
    padding-top: 12px;
    padding-bottom: 0px;
  }
}
.font-very-small {
  font-size: 10px;
}
.white-text{
  color: #ffffff;
}
.no-padding{
  padding-left: 0px;
  padding-right: 0px;
}
.no-left-padding{
  padding-left: 0px;
}
.padding-top-5{
  padding-top: 5px;
}
.margin-neg-7{
  margin-top: -7px;
}
.line-height-1{
  line-height: 1.2;
  padding-top: 2px;
}
button.d-lg-none.navbar-toggler {
  padding-top: 15px;
  padding-bottom: 0px;
}
.customOverlay {
  padding: 0px;
  color: #FFFFFF;
  background: rgba( 0,0,0,0.9);
  z-index: 1021;
}
.styles_modal__gNwvD.customModal,.customModal {
  top: 0px;
  max-width: calc(100vw);
  width: calc(100vw);
  height: calc(100vh );
  overflow: auto;
  z-index: 1000;
  background: transparent;
  @media only screen and (min-height: 689px){
    .vertical-mid-modal-content{
      // margin-top: calc(50vh - 394px);
    }
  }
}
.font-large{
  font-size: 22px;
}
.font-very-large{
  font-size: 30px;
}
.white-bottom-border,button.font-large.col-12.white-bottom-border.btn.transparent{
  border-bottom: 1px solid #FFFFFF;
}
.font-large.white-bottom-border {
  max-width: 250px;
  margin-left: auto;
  margin-right: auto;
}
.padding-top-10-bottom-10,.card-body.padding-top-10-bottom-10{
  padding-bottom: 10px;
}
.light-blue-text{
  color: #01aeef;
}
.margin-top-50{
  margin-top: 50px;
}
.yellow-text{
  color: #f9f108;
}
.margin-bottom-100{
  margin-bottom: 100px;
}
.padding-top-40{
  padding-top: 40px;
}
.padding-top-20{
  padding-top: 20px;
}
.padding-top-10,.card-body.padding-top-10{
  padding-top: 10px;
}
.clipwrapper{
  position: relative;
  height: 20px;
  width: 20px;
}
.clip{
  // position: absolute;
  // top: 0;
  // left: 0;
}
.pos-1{
  clip:rect(0 20px 20px 0);
}
.pos-2{
  clip:rect(0 96px 20px 20px);
  left: -20px;
}
.pos-3{
  clip:rect(20px 20px 96px 0);
  top: -20px;
}
.pos-4{
  clip:rect(20px 96px 96px 20px);
  top: -20px;
  left: -20px;
}
.curved-input{
    background: transparent;
    border-radius: 0px 20px 20px 0px;
}
.curved-input:active,.curved-input:focus,.curved-input:hover{
    background: transparent;
    color: #FFFFFF;
}
.left-curved{
    background: #FFFFFF;
    color: #000000;
    border-radius: 20px 0px 0px 20px;
}
.orange-text {
    color: orange;
}
.blue-button{
  background-color: #01aeef;
  color: #FFFFFF;
  padding-top: 8px;
  padding-bottom: 7px;
}
.blue-text.rounded-bordered{
  background: transparent;
  border: 1px solid #01aeef;
  border-radius: 15px 15px;
  color: #01aeef;
}
.blue-top{
  border-top: 1px solid #01aeef;
}
.row-100{width: calc(100%);}
.white-text.rounded-bordered{
  border: 1px solid #FFFFFF;
  border-radius: 15px 15px;
}
.btn.btn-transparent.rounded-bordered.white-text {
  margin-bottom: 5px;
  font-size: 10px;
}
hr.col-12{
  width: 99%;
}
.no-bottom-margin{
  margin-bottom: 0px;
}
.blue-bottom-border{
  border-bottom: 1px solid #01aeef;
  padding-bottom: 5px;
}
.margin-left-30{
  margin-left: 30px;
}
.no-margin,.row.no-margin{
  margin-left: 0px;
  margin-right: 0px;
}
.margin-right-5{
  margin-right: 5px;
}
.btn.white-button.rounded-bordered.margin-top-20 {
  background: #FFFFFF;
  color: #39b54a;
  border-radius: 15px 15px;
}
.btn.green-btn.rounded-bordered.blue-text.margin-top-20.margin-right-5 {
  border: 1px solid #39b54a;
  color: #39b54a;
}
button.transparent{
  background: transparent;
  border: 0px solid;
}
.chat-options.active{
  right: 19px;
}
.chat-options{
  position: fixed;
  // background: #CCC;
  // right: 19px;
  will-change: right;
  -webkit-transition: right 1s; /* Safari */
  transition: right 1s;
  right: -130px;
  bottom: 53px;
  a{
    background: #CCCCCC;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    padding: 0px 0px 0px 11px;
    font-size: 34px;
    i.fa.fa-whatsapp{

    }
  }
  a.whatsapp{
    background: #59f877;
    margin-right: 7px;
  }
  button.talkto{
    background: rgb(60, 188, 221);
    border-radius: 50%;
    height: 50px;
    width: 50px;
    border: 0px solid;
    padding: 0px 0px 0px 0px;
    font-size: 34px;
    img{
      margin-top: -10px;
    }
  }
}
#hide-on-contact-us,#contact-us-form{
  display: none;
}
#hide-on-contact-us.active,#contact-us-form.active{
  display: block;
}
.lp-empty-blue-grad{
  .content-div{
    margin-top: 0px;
    button{
      font-size: 21px;
      margin-top: -6px;
    }
  }
  border-radius: 45% 44% 0% 0%;
  background : -moz-linear-gradient(0% 50% 0deg,rgba(0, 233, 126, 1) 0%,rgba(0, 217, 233, 1) 100%);
  background : -webkit-linear-gradient(0deg, rgba(0, 233, 126, 1) 0%, rgba(0, 217, 233, 1) 100%);
  background : -webkit-gradient(linear,0% 50% ,100% 50% ,color-stop(0,rgba(0, 233, 126, 1) ),color-stop(1,rgba(0, 217, 233, 1) ));
  background : -o-linear-gradient(0deg, rgba(0, 233, 126, 1) 0%, rgba(0, 217, 233, 1) 100%);
  background : -ms-linear-gradient(0deg, rgba(0, 233, 126, 1) 0%, rgba(0, 217, 233, 1) 100%);
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#00E97E', endColorstr='#00D9E9' ,GradientType=0)";
  background : linear-gradient(90deg, rgba(0, 233, 126, 1) 0%, rgba(0, 217, 233, 1) 100%);
  box-shadow : 0px -2px 5px rgba(0, 0, 0, 0.25);
  filter: progid:DXImageTransform.Microsoft.dropshadow(OffX=0, OffY=-2, Color='#000000') progid:DXImageTransform.Microsoft.gradient(startColorstr='#00E97E',endColorstr='#00D9E9' , GradientType=1);
}
@media only screen and (max-width: 979px){
  iframe.chat-iframe {
    width: calc(100vw);
    height: calc(100vh);
  }
  .mobile-p-0{
    .col-12.row{
      padding-left: 0px;
      padding-right: 0px;
      margin: 0px;
    }
  }
  .chat-window.open{
    position: fixed;
    top: 0px;
    right: 0px;
    left: 0px;
    display: block;
    bottom: 0px;
    .chat-lp-div{
      position: absolute;
      right: 45px;
      height: 61px;
      line-height: 61px;
    }
  }
  .chat-window{
    display: none;
  }
}
.clear-both{
  clear: both;
}
@media only screen and (min-width: 980px){
  .logo-div{
    width: 95px;
  }
  .app-header{
    .nav-item .nav-link > .img-avatar{
      margin-right: 20px;
      margin-left: 20px;
    }
  }
  img.circular{
    border-radius: 50%;
  }
  .logo-inner-div{
    a{
      margin-left: -10px;
    }
    position: absolute;
    left: -28px;
    top: -14px;
    padding-top: 37px;
    background: #FFFFFF;
    border-radius: 50%;
    width: 116px;
    height: 116px;
    box-shadow: -9px 6px 23px -5px #CCC;
  }
  .navbar-nav{
    a.logo-text{
      color: #01aeef;
      font-size: 20px;
    }
  }
  .blue-curve{
    min-width: 69px;
    button.abs{
      border: 0px;
      border-radius: 0% 0% 0% 72%;
      position: absolute;
      top: 0px;
      width: 80px;
      height: 55px;
      .navbar-toggler-icon{
      }
    }
  }
  .lp-pal-pal-grad.padding-5-15{
    padding: 5px 15px 5px 15px;
  }
  .lp-pal-pal-grad{
    background : -moz-linear-gradient(0% 50% 0deg,rgba(154, 127, 255, 1) 2.71%,rgba(254, 42, 255, 1) 100%);
    background : -webkit-linear-gradient(0deg, rgba(154, 127, 255, 1) 2.71%, rgba(254, 42, 255, 1) 100%);
    background : -webkit-gradient(linear,0% 50% ,100% 50% ,color-stop(0.0271,rgba(154, 127, 255, 1) ),color-stop(1,rgba(254, 42, 255, 1) ));
    background : -o-linear-gradient(0deg, rgba(154, 127, 255, 1) 2.71%, rgba(254, 42, 255, 1) 100%);
    background : -ms-linear-gradient(0deg, rgba(154, 127, 255, 1) 2.71%, rgba(254, 42, 255, 1) 100%);
    -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#9A7FFF', endColorstr='#FE2AFF' ,GradientType=0)";
    background : linear-gradient(90deg, rgba(154, 127, 255, 1) 2.71%, rgba(254, 42, 255, 1) 100%);
    color: #FFFFFF;
    border-radius : 16px;
    -moz-border-radius : 16px;
    -webkit-border-radius : 16px;
    filter: alpha(opacity=86) progid:DXImageTransform.Microsoft.Alpha(opacity=86) progid:DXImageTransform.Microsoft.gradient(startColorstr='#9A7FFF',endColorstr='#FE2AFF' , GradientType=1);
  }
  .lp-blue-grad{
    background : -moz-linear-gradient(0% 50% 0deg,rgba(0, 191, 212, 1) 0%,rgba(21, 75, 255, 1) 100%);
    background : -webkit-linear-gradient(0deg, rgba(0, 191, 212, 1) 0%, rgba(21, 75, 255, 1) 100%);
    background : -webkit-gradient(linear,0% 50% ,100% 50% ,color-stop(0,rgba(0, 191, 212, 1) ),color-stop(1,rgba(21, 75, 255, 1) ));
    background : -o-linear-gradient(0deg, rgba(0, 191, 212, 1) 0%, rgba(21, 75, 255, 1) 100%);
    background : -ms-linear-gradient(0deg, rgba(0, 191, 212, 1) 0%, rgba(21, 75, 255, 1) 100%);
    -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#00BFD4', endColorstr='#154BFF' ,GradientType=0)";
    background : linear-gradient(90deg, rgba(0, 191, 212, 1) 0%, rgba(21, 75, 255, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00BFD4',endColorstr='#154BFF' , GradientType=1);
  }
  .refer-and-earn-avatar{
    margin-right: 5px;
  }
  .font-25{
    font-size: 25px;
  }
  .vertical-align-super{
    vertical-align: super;
  }
  .padding-right-30{
    padding-right: 30px;
  }
  section.main-menu-list {
    position: fixed;
    top: 0px;
    border-radius: 0% 0% 0% 71%;
    right: calc(-100vw);
    will-change: right;
    -webkit-transition: right 1s; /* Safari */
    transition: right 1s;
    .font-large.white-bottom-border{
      margin-right: 0px;
    }
    .min-height-one{
      min-height: 60px;
    }
    .max-width-250{
      max-width: 250px;
      margin-right: 0px;
      margin-left: auto;
      text-align: left;
      color: #FFFFFF;
      margin-bottom: 10px;
    }
    .min-width-250{
      min-width: 250px;
      padding-left: 0px;
    }
    button.min-width-250:hover{
      background: transparent;
    }
    .white-bottom-border {
      border-bottom: 1px solid transparent;
    }
    .max-width-250.active{
      color: #f9f108;
    }
    .white-bottom-border.active{
      border-bottom: 1px solid #FFFFFF;
    }
    a{
      cursor: pointer;
    }
    a:hover,button:hover{
      text-decoration: none;
      color: #f9f108;
    }
  }
  section.main-menu-list.active{
    right: 0px;
    max-width: 460px;
  }
  .testimonial_background{
    background : -moz-linear-gradient(18.57% -59.3% -45deg,rgba(255, 199, 248, 1) 8.31%,rgba(127, 231, 255, 1) 100%);
    background : -webkit-linear-gradient(-45deg, rgba(255, 199, 248, 1) 8.31%, rgba(127, 231, 255, 1) 100%);
    background : -webkit-gradient(linear,18.57% -59.3% ,81.43% 159.3% ,color-stop(0.0831,rgba(255, 199, 248, 1) ),color-stop(1,rgba(127, 231, 255, 1) ));
    background : -o-linear-gradient(-45deg, rgba(255, 199, 248, 1) 8.31%, rgba(127, 231, 255, 1) 100%);
    background : -ms-linear-gradient(-45deg, rgba(255, 199, 248, 1) 8.31%, rgba(127, 231, 255, 1) 100%);
    -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFC7F8', endColorstr='#7FE7FF' ,GradientType=0)";
    background : linear-gradient(135deg, rgba(255, 199, 248, 1) 8.31%, rgba(127, 231, 255, 1) 100%);
    border-radius : 26px;
    -moz-border-radius : 26px;
    -webkit-border-radius : 26px;
    box-shadow : 0px 0px 10px rgba(0, 0, 0, 0.15);
    filter: progid:DXImageTransform.Microsoft.dropshadow(OffX=0, OffY=0, Color='#000000') progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFC7F8',endColorstr='#7FE7FF' , GradientType=1);
  }
}
.hidden {
  display: none;
}
.tab-inactive{
  background: transparent !important;
  color: #000000;
  select{
    color: #000000;
  }
}
.tab-active{
  color: #FFFFFF !important;
}
.tab-active.workshop-tab{
  color: #000000 !important;
}
p.m-0.bold {
  font-weight: 900;
}
.border-right.float-left.col-4.col-md-4.col-lg-4 {
  min-height: 100px;
}
.btn-city-btn.select-training.tab-inactive.btn.btn-secondary,.city-select-desktop.tab-inactive {
  background: #E0E0E0 !important;
}
.btn-city-btn.select-training.tab-inactive.btn.btn-secondary.workshop-tab, .city-select-desktop.tab-inactive.workshop-tab{
  background: rgb(35, 40, 44) !important;
  color: #000000;
}
.footer-bg.text-white.p-4.trademark-container{
  padding-top: 0px !important;
  h6{
    font-size: 10px;
  }
}
.participant-detail-btn{
  position: absolute;
  top: 0px;
  right: 22px;
  padding: 3px 5px;
  background: #5f4bb6;
  border-radius: 0px 0px 7px 7px;
}
.participant-modal {
  min-width: calc(90vw);
  border-radius: 30px;
  .close-btn{
    position: fixed;
    top: 72px;
    right: 76px;
    background: #FFFFFF;
    border-radius: 50%;
  }
}
.participant-invite-card.col {
  max-width: 210px;
  background-color: rgba(204, 204, 204, 0.25);
  text-align: center;
  padding: 15px 0px;
  border-radius: 15px;
  margin-top: 20px;
  margin-bottom: 20px;
  display: inline-block;
  margin-right: 15px;
  input {
    max-width: 150px;
    margin: 1px auto;
    text-align: center;
    background: transparent;
    color: #FFFFFF;
    border-color: transparent;
  }
  input.error {
    border: 1px solid rgba(255, 0, 0, 0.52) !important;
  }
  input:hover,input:active {
    background: rgba(255, 254, 254, 0.34901960784313724);
    color: #FFFFFF;
  }
  ::placeholder {
    color: #FFFFFF;
  }
  button.btn{
    min-width: 110px;
    border-radius: 15px;
    background-color: #cccccc5c;
    border: 0px;
    color: #ffffff;
    margin: 10px auto;
  }
}
